.app-common-cmp-input {
  outline: none;
  border: 0;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 26px;

  &-wrapper {
    transition: background-color 0.35s ease-in-out;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 54px;

    &.noBg {
      background: none !important;
    }
    &.textaread {
      background: none !important;
      height: auto;
      align-items: flex-start;
    }
    &.disabled {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }

  &.textaread {
    height: auto;
    resize: none;
    overflow: hidden;
  }

  &-label {
    position: absolute;
    top: 6px;
    left: 0;
    transition: opacity 0.1s ease-in-out;

    &-none {
      display: none;
    }
  }

  &.labeled {
    width: 100%;
    height: 100%;
  }

  &.light {
    color: #000;
    &::placeholder {
      transition: color 0.35s ease-in-out;
      color: rgba($color: #000, $alpha: 0.35);
    }
  }
  &.dark {
    color: #fff;
    &::placeholder {
      transition: color 0.35s ease-in-out;
      color: rgba($color: #fff, $alpha: 0.35);
    }
  }
}
