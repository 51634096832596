@import "reset";

html {
  scrollbar-width: none;
}

body {
  margin: 0;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & * {
    box-sizing: border-box;
  }
}

.d-flex {
  display: flex;
}
.f {
  &-col {
    flex-direction: column;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-ccenter {
    justify-content: center;
  }
  &-center {
    justify-items: center;
  }
  &-acenter {
    align-items: center;
  }
}

.w {
  &-25 {
    width: 25% !important;
  }
  &-50 {
    width: 50% !important;
  }
  &-100 {
    width: 100% !important;
  }
}
.h {
  &-100 {
    height: 100% !important;
  }
}

.on-hover {
  &:hover {
    cursor: pointer;
  }
  &-input {
    &:hover {
      cursor: text;
    }
  }
}

.text-no-decoration {
  text-decoration: none;
}

.app {
  &-modal {
    position: absolute;
    left: 18%;
    right: 18%;
    top: 15%;

    &.auto-center {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: max-content;
    }

    height: fit-content;
    max-height: 70%;

    border-radius: 12px;
    background: none;
    outline: none;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);

    opacity: 1;
    animation: showUpModal 0.35s ease-in-out 0s;

    @media (max-width: 925px) {
      &:not(.static) {
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        max-height: 100% !important;
        width: 100% !important;
        border-radius: 0 !important;
      }
    }

    @keyframes showUpModal {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    background-color: #fff;

    &-preview {
      width: auto !important;
      height: max-content !important;
      max-width: 90% !important;
      max-height: 90% !important;
      background: none !important;
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    overflow: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);

    opacity: 1;
    animation: showUpOverlay 0.35s ease-in-out 0s;

    @keyframes showUpOverlay {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &-layout {
    background: none !important;

    @media (max-width: 1240px) {
      padding: 24px 0 !important;
    }
  }

  &-loader-anim {
    & svg path {
      animation: change-color 5s ease-in-out infinite 1.5s;

      @keyframes change-color {
        0% {
          fill: #000;
        }
        10% {
          fill: #eee;
        }
        20% {
          fill: #222;
        }
        100% {
          fill: #000;
        }
      }
    }
  }
}

.attachment {
  &-image {
    &-preview {
      &-container {
        display: flex;
        justify-content: center;
        justify-items: center;
        position: relative;
        overflow: hidden;
        top: 0;
        left: 0;
        border-radius: 20px;
      }

      &-cell {
        grid-column: 1 / -1 !important;
      }

      &-img {
        border-radius: 20px;
        height: inherit;
        object-fit: cover;

        &-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
        }
      }

      &-modal {
        width: auto;
        height: max-content;
        max-width: 90%;
        max-height: 90%;
        background: none !important;
      }
    }
  }
}

.use-case {
  &-cover {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: 250px;
      min-height: 250px;
      border-radius: 8px;
      border: 2px dashed rgba(0, 0, 0, 0.15);

      &:hover {
        cursor: pointer;
      }
    }
  }
}
